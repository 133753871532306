/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import { MEDIA } from '../../../static/constants/media';
import * as S from './MediaDesktop.styles';
import SingleMediaItem from '../SingleMediaItem';

const MediaDesktop = ({ isOpen }) => {
  return (
    <S.Wrapper $isOpen={isOpen}>
      <S.Column>
        {MEDIA.map((mediaItem, index) => {
          if (index % 2 !== 0) return null;

          const { id, mediaName, title, description, url, videoSrc } =
            mediaItem;

          return (
            <SingleMediaItem
              key={id}
              id={id}
              mediaName={mediaName}
              title={title}
              description={description}
              videoSrc={videoSrc}
              url={url}
            />
          );
        })}
      </S.Column>

      <S.Column>
        {MEDIA.map((mediaItem, index) => {
          if (index % 2 === 0) return null;

          const { id, mediaName, title, description, url, videoSrc } =
            mediaItem;

          return (
            <SingleMediaItem
              key={id}
              id={id}
              mediaName={mediaName}
              title={title}
              description={description}
              videoSrc={videoSrc}
              url={url}
            />
          );
        })}
      </S.Column>
    </S.Wrapper>
  );
};

export default MediaDesktop;

MediaDesktop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
