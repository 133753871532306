/* eslint-disable indent */
import styled from 'styled-components';
import {
  sharedCardElement,
  sharedHeading2,
  sharedHeading3,
  sharedIcon,
  sharedParagraph,
} from './SharedCardItemStyles';

export const Article = styled.div`
  ${sharedCardElement};
`;

export const ArticleIcon = styled.img`
  ${sharedIcon};
`;

export const Authors = styled.h3`
  ${sharedHeading3}
`;

export const Title = styled.h4`
  ${sharedHeading2}
`;

export const Content = styled.p`
  ${sharedParagraph};

  margin: 0 0 2.4rem 0;

  &::after {
    content: '...';
  }
`;

export const Footer = styled.p`
  ${sharedParagraph};

  font-style: italic;
`;

export const Doi = styled.p`
  ${sharedParagraph};

  a {
    color: inherit;
  }
`;

export const DoiSpan = styled.span`
  ${sharedParagraph};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;
