import { css } from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const sharedCardElement = css`
  padding: 2.4rem 1.6rem;
  position: relative;

  &:nth-of-type(2n) {
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
  }

  @media ${query.desktop} {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 6.4rem 4rem 4rem;

    &:nth-of-type(2n) {
      background-color: ${({ theme }) => theme.color.backgroundPrimary};
    }
  }
`;

export const sharedIcon = css`
  display: none;

  @media ${query.desktop} {
    display: block;
    width: 73px;
    height: 73px;

    position: absolute;
    left: 40px;
    top: -36px;
  }
`;

export const sharedParagraph = css`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2.8rem;
`;

export const sharedHeading3 = css`
  color: #000000;
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.navigation};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2rem;
  margin: 0 0 1rem 0;
`;

export const sharedHeading2 = css`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.paragraphBig};
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  line-height: 3.6rem;
  margin: 0 0 1.6rem 0;
`;
