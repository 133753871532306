import React from 'react';
import PropTypes from 'prop-types';
import * as S from './ArticlesDesktop.styles';
import SingleArticle from '../SingleArticle';
import useArticles from '../../../hooks/useArticles';

const ArticlesDesktop = ({ isOpen }) => {
  const { articles } = useArticles();

  return (
    <S.Wrapper $isOpen={isOpen}>
      <S.Column>
        {articles.map((article, index) => {
          if (index % 2 !== 0) return null;

          const { id, authors, title, content, footer, doi, publicURL } =
            article;
          return (
            <SingleArticle
              key={id}
              id={id}
              authors={authors}
              title={title}
              content={content}
              footer={footer}
              doi={doi}
              publicURL={publicURL}
            />
          );
        })}
      </S.Column>

      <S.Column>
        {articles.map((article, index) => {
          if (index % 2 === 0) return null;

          const { id, authors, title, content, footer, doi, publicURL } =
            article;
          return (
            <SingleArticle
              key={id}
              id={id}
              authors={authors}
              title={title}
              content={content}
              footer={footer}
              doi={doi}
              publicURL={publicURL}
            />
          );
        })}
      </S.Column>
    </S.Wrapper>
  );
};

export default ArticlesDesktop;

ArticlesDesktop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
