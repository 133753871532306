import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../static/constants/routes';

import * as S from './Book.styles';
import PrimaryButtonWithLink from '../Common/Buttons/PrimaryButtonWithLink';
import SectionWrapper from '../Common/SectionWrapper';

const Book = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper backgroundColor="#ffffff" removePaddingVerticalDesktop>
      <S.Wrapper>
        <StaticImage
          src="../../assets/images/book.png"
          alt="book"
          placeholder="blurred"
          className="book-image-wrapper"
          imgClassName="book-image"
        />
        <S.Content className="content">
          <S.Name>DR MARIA KMITA</S.Name>
          <S.Title>Poprawczak nastroju</S.Title>
          <S.Description>{t('publications_book_description')}</S.Description>
          <S.ButtonWrapper>
            <PrimaryButtonWithLink text="więcej" routeTo={routes.poprawczak} />
          </S.ButtonWrapper>
        </S.Content>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default Book;
