import styled from 'styled-components';
import { query } from '../../../styles/mediaQuery';

export const OuterWrapper = styled.div`
  background-color: transparent;
`;
export const InnerWrapper = styled.div`
  margin: 2.4rem auto;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Tab = styled.div`
  background-color: ${({ theme, $isOpen }) =>
    $isOpen ? theme.color.primaryOrange : theme.color.textSecondary};
  padding: 1.4rem;
  margin: 0 1.6rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  & .arrow-icon {
    transform: ${({ $isOpen }) => ($isOpen ? '' : 'rotate(180deg)')};
  }

  @media ${query.tablet} {
    padding: 2.4rem;
  }
`;

export const TabIcon = styled.img``;

export const ArrowIcon = styled.img`
  transform: rotate(180deg);
`;

export const TabText = styled.h2`
  color: ${({ theme }) => theme.color.backgroundPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 2.8rem;
  margin: 0 auto 0 1.6rem;
`;
