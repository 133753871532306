import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './OtherPublicationsDesktop.styles';
import SectionWrapper from '../../Common/SectionWrapper';
import articlesIcon from '../../../assets/icons/articles-icon.svg';
import articlesIconOrange from '../../../assets/icons/articles-icon-orange.svg';
import mediaIcon from '../../../assets/icons/media-icon.svg';
import mediaIconOrange from '../../../assets/icons/media-icon-orange.svg';
import { theme } from '../../../styles/theme';
import ArticlesDesktop from './ArticlesDesktop';
import MediaDesktop from './MediaDesktop';

const TABS = {
  articles: 'articles',
  media: 'media',
};

const OtherPublicationsDesktop = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.media);

  return (
    <>
      <S.Tabs>
        <S.Tab
          $isOpen={activeTab === TABS.articles}
          $isLeft
          onClick={() => setActiveTab(TABS.articles)}
        >
          <S.TabContent $alignRight>
            <S.TabIcon
              src={
                activeTab === TABS.articles ? articlesIconOrange : articlesIcon
              }
              alt="articles-icon"
            />
            <S.TabText>{t('publications_articles')}</S.TabText>
          </S.TabContent>
        </S.Tab>
        <S.Tab
          $isOpen={activeTab === TABS.media}
          onClick={() => setActiveTab(TABS.media)}
        >
          <S.TabContent>
            <S.TabIcon
              src={activeTab === TABS.media ? mediaIconOrange : mediaIcon}
              alt="articles-icon"
            />
            <S.TabText>{t('publications_media')}</S.TabText>
          </S.TabContent>
        </S.Tab>
      </S.Tabs>
      <SectionWrapper backgroundColor={theme.color.backgroundSecondary}>
        <ArticlesDesktop isOpen={activeTab === TABS.articles} />
        <MediaDesktop isOpen={activeTab === TABS.media} />
      </SectionWrapper>
    </>
  );
};

export default OtherPublicationsDesktop;
