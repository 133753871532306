export const MEDIA = [
  {
    id: '1',
    mediaName: 'Telewizja TVN',
    title: 'Dzień dobry TVN: Śmiech lekiem na całe zło',
    description: 'Czym się zajmuje Optymisja i dlaczego humor jest ważny.',
    url: 'https://dziendobry.tvn.pl/zdrowie/psychologia/rodzina-duziakow-smiesznymi-filmikami-robia-sobie-terapie-da308717-5329727',
    videoSrc:
      'https://firebasestorage.googleapis.com/v0/b/optymisja-64d94.appspot.com/o/tvn-video-maria.mp4?alt=media',
  },
  {
    id: '2',
    mediaName: 'TVP Wrocław',
    title: 'Śmiechoterapia dla seniorów',
    description:
      'Walka ze spokojną starością. Z czego śmieją się seniorzy i czy starość to nie-radość?',
    url: 'https://www.facebook.com/optymisja/videos/444463749449023',
    videoSrc:
      'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Foptymisja%2Fvideos%2F444463749449023%2F&show_text=false&width=560&t=0',
  },
  {
    id: '3',
    mediaName: 'TVP Wrocław',
    title: 'Walka z humoroidami',
    description: 'Humor i depresja',
    url: 'https://www.facebook.com/optymisja/videos/371137530388313',
    videoSrc:
      'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Foptymisja%2Fvideos%2F371137530388313%2F&show_text=false&width=560&t=0',
  },
  {
    id: '4',
    mediaName: 'Radio Eska',
    title: 'Warsztaty humoru - skąd się biorą pomysły w tej głowie?',
    description: 'Warsztaty humoru od kuchni',
    url: 'https://www.facebook.com/optymisja/videos/2807246996244042',
    videoSrc:
      'https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Foptymisja%2Fvideos%2F2807246996244042%2F&show_text=false&width=315&t=0',
  },
  {
    id: '5',
    mediaName: 'RMF24',
    title: 'Śmiechoterapia. Najlepsza na poprawę humoru!',
    description:
      'Styczeń to czas, kiedy możemy mieć spadek nastoju. Przyczyniają się do tego krótkie dni czy mało słońca. Sposobów na to, jak się rozweselić szukamy w cyklu „Twoje Zdrowie w Faktach RMF FM”. I tak dotarliśmy do śmiechoterapeuty - dr Marii Kmity z Uniwersytetu Medycznego we Wrocławiu.',
    url: 'https://twojezdrowie.rmf24.pl/cialo/newsamp-smiechoterapia-najlepsza-na-poprawe-humoru,nId,5764519',
  },
  {
    id: '6',
    mediaName: 'Radio Wrocław',
    title: 'Chwalimy swoje: dr Maria Kmita, humorolożka',
    description:
      'Jak mieć dobry humor w kiepskich czasach? Czy powinniśmy się śmiać, gdy za naszą granicą umierają ludzie? O tym w Chwalimy swoje, z dr Marią Kmitą z Uniwersytetu Medycznego we Wrocławiu.',
    url: 'https://www.radiowroclaw.pl/articles/view/117788/Chwalimy-swoje-dr-Maria-Kmita-humorolozka',
  },
  {
    id: '7',
    mediaName: 'Gazeta Wrocławska',
    title: 'Czy w czasie wojny można się śmiać?',
    description:
      'Z doktor Marią Kmitą, humorolożką i śmiechoterapeutką z Wrocławia, o wojnie i o tym czy można się z niej śmiać, rozmawia Robert Migdał.',
    url: 'https://gazetawroclawska.pl/czy-w-czasie-wojny-mozna-sie-smiac-tak-bo-zartujemy-zeby-rozladowac-napiecie-i-poradzic-sobie-z-problemem-rozmowa/ar/c13-16214147',
  },
  {
    id: '8',
    mediaName: 'Gazeta Wyborcza',
    title:
      '"Jojo Rabbit": Zabawny Hitler to przesada? Przecież humor był nawet w obozach',
    description: 'O czarnym humorze. Analiza filmu Jojo Rabbit.',
    url: 'https://wyborcza.pl/7,101707,25618523,oscary-2020-jojo-rabbit-czy-hitler-moze-byc-zabawny.html?disableRedirects=true',
  },
  {
    id: '10',
    mediaName: 'Radio Luz',
    title:
      'Śmiech lekiem na całe zło – rozmowa z dr Marią Kmitą o śmiechoterapii',
    description:
      'Uśmiechając się, z dr Marią Kmitą, wrocławską śmiechoterapeutką i humorolożką, rozmawia Martyna Dziakowicz.',
    url: 'https://radioluz.pl/podcasty/?search=Kmita&category=nauka',
  },
  {
    id: '9',
    mediaName: 'Wprost',
    title:
      'Śmiech lekiem na całe zło? Humorolog i śmiechoterapeuta o jego zaletach',
    description:
      'Humorolog i śmiechoterapeuta dr Maria Kmita opowiedziała, jak wpływa na nas śmiech. – Rozluźnia nasze mięśnie, lepiej pracuje serce, lepiej oddychamy – powiedziała. – Daje nam pozytywne nastawienie do wszystkiego – dodała. Jak ważne jest śmianie się z samych siebie?',
    url: 'https://zdrowie.wprost.pl/psychologia/terapie/10287682/smiech-lekiem-na-cale-zlo-humorolog-i-smiechoterapeuta-o-zaletach.html',
  },
  {
    id: '11',
    mediaName: 'Radio Złote Przeboje',
    title: 'Doktor Maria Kmita gościem Odety Moro!',
    description:
      'Doktor Maria Kmita, humorolog i śmiechoterapeuta, opowiedziała nam o tym, czy humor zależy od narodu… Co dzieje się z naszym ciałem, kiedy zaczynamy się śmiać? Czy śmiech może leczyć?',
    url: 'https://zloteprzeboje.pl/podcast/doktor-maria-kmita-gosciem-odety-moro/40914',
  },
  {
    id: '12',
    mediaName: 'Echo 24',
    title: 'Śmiech to najlepsze lekarstwo',
    description:
      'O poczuciu humoru, opowiadaniu żartów, memach i wszystkim, co związane ze śmiechem mówi dr Maria Kmita, humorolożka i śmiechoterapeutka. Opowiada o prowadzonych przez nią warsztatach m.in. dla seniorów i dzieci.',
    url: 'https://echo24.tv/rozmowa/smiech-to-najlepsze-lekarstwo/',
  },
  {
    id: '13',
    mediaName: 'Wrocławskie Centrum Rozwoju Społecznego',
    title: 'Kampania Społeczna "Solidarnie, zdrowo, międzypokoleniowo"',
    description: 'Kampania zachęcająca do szczepień wśród seniorów',
    url: 'https://www.facebook.com/optymisja/videos/248022197179305/',
    videoSrc:
      'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Foptymisja%2Fvideos%2F248022197179305%2F&show_text=false&width=560&t=0',
  },
];
