import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  @media ${query.desktop} {
    margin: 5rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2rem;
  }

  .earphones-img-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    max-width: 400px;

    @media ${query.desktop} {
      max-width: 550px;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  position: relative;

  @media ${query.desktop} {
    grid-column: 1/3;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 3.6rem;
  margin: 0 0 1.6rem;

  @media ${query.desktop} {
    width: 50%;
    font-size: ${({ theme }) => theme.font.size.heading};
    line-height: 5.4rem;
    margin: 0 0 3rem;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2.8rem;
  padding: 0 0 12rem;

  @media ${query.desktop} {
    width: 45%;
    padding: 0 0 4rem;
  }
`;

export const ImageWrapper = styled.div``;

export const PodcastLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  margin-top: 0.8rem;

  @media ${query.desktop} {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
  }
`;

export const PodcastIcon = styled.img`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.textPrimary};
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
`;

export const WaveIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 0;
    right: 25%;
  }
`;

export const PodcastLink = styled.a`
  width: 100%;
  height: 4.7rem;
`;
