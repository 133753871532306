import React from 'react';
import PropTypes from 'prop-types';

import { MEDIA } from '../../../static/constants/media';
import * as S from './MediaMobile.styles';
import SingleMediaItem from '../SingleMediaItem';

const MediaMobile = ({ isOpen }) => {
  return (
    <S.Wrapper $isOpen={isOpen}>
      {MEDIA.map((mediaItem) => {
        const { id, mediaName, title, description, url, videoSrc } = mediaItem;

        return (
          <SingleMediaItem
            key={id}
            id={id}
            mediaName={mediaName}
            title={title}
            description={description}
            videoSrc={videoSrc}
            url={url}
          />
        );
      })}
    </S.Wrapper>
  );
};

export default MediaMobile;

MediaMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
