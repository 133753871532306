/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import * as S from './Podcast.styles';
import SectionWrapper from '../Common/SectionWrapper';
import { PrimaryButton } from '../Common/Buttons/Buttons.styles';
import waveIcon from '../../assets/icons/wave-big-orange.svg';
import PodcastModal from './PodcastModal';
import { externalLinks } from '../../static/constants/externalLinks';
import PodcastLinkButton from '../Common/Buttons/PodcastLinkButton';
import SpotifyIcon from '../Common/Icons/SpotifyIcon';
import ApplePodcastsIcon from '../Common/Icons/ApplePodcastsIcon';
import GooglePodcastsIcon from '../Common/Icons/GooglePodcastsIcon';
import YouTubeIcon from '../Common/Icons/YouTubeIcon';

const Podcast = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <SectionWrapper removePaddingVerticalDesktop>
      <S.Wrapper>
        <S.DescriptionWrapper>
          <S.WaveIcon src={waveIcon} alt="wave-icon" />
          <S.Title>{t('publications_podcast_title')}</S.Title>
          <S.Description>{t('publications_podcast_description')}</S.Description>

          <StaticImage
            src="../../assets/images/earphones.png"
            alt="earphones"
            placeholder="blurred"
            className="earphones-img-wrapper"
          />
        </S.DescriptionWrapper>
        <PrimaryButton
          onClick={() => setIsModalOpen(true)}
          $widthDesktop="30rem"
        >
          {t('publications_podcast_button')}
        </PrimaryButton>
        <S.PodcastLinks>
          <PodcastLinkButton routeTo={externalLinks.podcast.spotify}>
            <SpotifyIcon />
          </PodcastLinkButton>
          <PodcastLinkButton routeTo={externalLinks.podcast.apple}>
            <ApplePodcastsIcon />
          </PodcastLinkButton>

          <PodcastLinkButton routeTo={externalLinks.podcast.googlePodcasts}>
            <GooglePodcastsIcon />
          </PodcastLinkButton>
          <PodcastLinkButton routeTo={externalLinks.youTube}>
            <YouTubeIcon />
          </PodcastLinkButton>
        </S.PodcastLinks>
      </S.Wrapper>
      <PodcastModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </SectionWrapper>
  );
};

export default Podcast;
