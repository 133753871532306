/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './OtherPublicationsMobile.styles';
import articlesIcon from '../../../assets/icons/articles-icon.svg';
import mediaIcon from '../../../assets/icons/media-icon.svg';
import arrowIcon from '../../../assets/icons/arrow-white.svg';

import MediaMobile from './MediaMobile';
import ArticlesMobile from './ArticlesMobile';

const OtherPublicationsMobile = () => {
  const { t } = useTranslation();
  const [isArticlesOpen, setIsArticlesOpen] = useState(false);
  const [isMediaOpen, setIsMediaOpen] = useState(false);
  const articlesRef = useRef();
  const mediaRef = useRef();

  const scrollToTab = (ref) => {
    const yOffset = -10;
    const topDistance =
      ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: topDistance, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!isArticlesOpen) return;

    scrollToTab(articlesRef);
  }, [isArticlesOpen]);

  useEffect(() => {
    if (!isMediaOpen) return;

    scrollToTab(mediaRef);
  }, [isMediaOpen]);

  return (
    <S.OuterWrapper>
      <S.InnerWrapper>
        <S.Tab
          $isOpen={isArticlesOpen}
          onClick={() => setIsArticlesOpen(!isArticlesOpen)}
          ref={articlesRef}
        >
          <S.TabIcon src={articlesIcon} alt="article-icon" />
          <S.TabText>{t('publications_articles')}</S.TabText>
          <img className="arrow-icon" src={arrowIcon} alt="arrow-icon" />
        </S.Tab>
        <ArticlesMobile isOpen={isArticlesOpen} />
        <S.Tab
          $isOpen={isMediaOpen}
          onClick={() => setIsMediaOpen(!isMediaOpen)}
          ref={mediaRef}
        >
          <S.TabIcon src={mediaIcon} alt="media-icon" />
          <S.TabText>{t('publications_media')}</S.TabText>
          <img className="arrow-icon" src={arrowIcon} alt="arrow-icon" />
        </S.Tab>
        <MediaMobile isOpen={isMediaOpen} />
      </S.InnerWrapper>
    </S.OuterWrapper>
  );
};

export default OtherPublicationsMobile;
