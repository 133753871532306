/* eslint-disable indent */
import styled from 'styled-components';
import {
  sharedCardElement,
  sharedHeading2,
  sharedHeading3,
  sharedIcon,
  sharedParagraph,
} from './SharedCardItemStyles';

export const Media = styled.div`
  ${sharedCardElement};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MediaIcon = styled.img`
  ${sharedIcon};
`;

export const MediaName = styled.h3`
  ${sharedHeading3};
`;

export const Title = styled.h4`
  ${sharedHeading2};
`;

export const Description = styled.p`
  ${sharedParagraph};

  margin: 0 0 2.4rem 0;
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    width: 100%;
  }
`;

export const MoreBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-top: 2.4rem;
`;

export const MoreBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.button};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-transform: uppercase;
`;

export const ArrowIcon = styled.img`
  margin-left: 1rem;
`;
