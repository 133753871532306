/* eslint-disable indent */
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`;

export const Tab = styled.div`
  width: 100%;
  background-color: ${({ theme, $isOpen }) =>
    $isOpen ? theme.color.backgroundSecondary : theme.color.primaryOrangeDim};
  border-radius: ${({ $isLeft }) => ($isLeft ? '0 5px 0 0' : '5px 0 0 0')};
  cursor: pointer;
  display: flex;
  padding: 4rem 6rem;

  & h2 {
    color: ${({ theme, $isOpen }) =>
      $isOpen ? theme.color.primaryOrange : theme.color.backgroundPrimary};
  }
`;

export const TabContent = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  margin-left: ${({ $alignRight }) => ($alignRight ? 'auto' : '0')};
`;

export const TabIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 4rem;
`;

export const TabText = styled.h2`
  /* color: ${({ theme }) => theme.color.backgroundPrimary}; */
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingMedium};
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  line-height: 4.8rem;
  margin: 0;
`;
