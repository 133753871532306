/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

import * as S from './ArticlesMobile.styles';
import SingleArticle from '../SingleArticle';
import useArticles from '../../../hooks/useArticles';

const ArticlesMobile = ({ isOpen }) => {
  const { articles } = useArticles();

  return (
    <S.Wrapper $isOpen={isOpen}>
      {articles.map((article) => {
        const { id, authors, title, content, footer, doi, publicURL } = article;

        return (
          <SingleArticle
            key={id}
            id={id}
            authors={authors}
            title={title}
            content={content}
            footer={footer}
            doi={doi}
            publicURL={publicURL}
          />
        );
      })}
    </S.Wrapper>
  );
};

export default ArticlesMobile;

ArticlesMobile.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
