import styled from 'styled-components';

export const Wrapper = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'grid' : 'none')};
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7rem;
`;
