import { graphql, useStaticQuery } from 'gatsby';
import { ARTICLES } from '../static/constants/articles';

const useArticles = () => {
  const data = useStaticQuery(graphql`
    query Articles {
      allFile(filter: { extension: { eq: "pdf" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `);

  const articles = ARTICLES.map((article) => {
    const { pdfName } = article;
    const { publicURL } = data.allFile.nodes.find(
      (node) => node.name === pdfName,
    );

    const newArticle = {
      ...article,
      publicURL,
    };

    return newArticle;
  });

  return { articles };
};

export default useArticles;
