import styled from 'styled-components';

export const Wrapper = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  gap: 4rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7rem;
`;
