import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  height: 45rem;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: radial-gradient(
    325.2% 1306.49% at 36.46% 57.5%,
    #e6d295 19.79%,
    #efe2b8 88.54%
  );
  overflow: hidden;

  @media ${query.desktop} {
    margin: 5rem 0;
    height: 30rem;
  }

  .book-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    @media ${query.desktop} {
      width: 70%;
      left: -10%;
    }
  }

  .book-image {
    object-fit: cover;
    height: 150%;
    top: -50%;
  }
`;

export const Content = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding: 1rem 0.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: end;

  @media ${query.desktop} {
    left: 50%;
  }
`;

export const Name = styled.h4`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin: 0 0 1.6rem;

  @media ${query.desktop} {
    margin: 0 0 1rem;
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 900;
  margin: 0 0 1rem;

  @media ${query.desktop} {
    color: ${({ theme }) => theme.color.backgroundPrimary};
    font-size: ${({ theme }) => theme.font.size.headingMedium};
    line-height: 7rem;
  }

  @media ${query.desktopLg} {
    font-size: ${({ theme }) => theme.font.size.heading};
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 2.8rem;
  margin: 0 0 1rem;

  @media ${query.desktop} {
    color: ${({ theme }) => theme.color.backgroundPrimary};
    font-family: ${({ theme }) => theme.font.family.archivo};
    font-size: ${({ theme }) => theme.font.size.button};
    font-weight: ${({ theme }) => theme.font.weight.light};
    line-height: 3.6rem;
  }

  @media ${query.desktopLg} {
    font-size: ${({ theme }) => theme.font.size.paragraphBig};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0.8rem 0;
`;
