import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from './SingleMediaItem.styles';
import arrowIcon from '../../assets/icons/arrow-with-vector.svg';
import mediaIcon from '../../assets/icons/media-icon-orange.svg';

const SingleMediaItem = ({
  id,
  mediaName,
  title,
  description,
  videoSrc,
  url,
}) => {
  const { t } = useTranslation();

  return (
    <S.Media key={id}>
      <S.MediaIcon src={mediaIcon} alt="media-icon" />
      <S.MediaName>{mediaName}</S.MediaName>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      {videoSrc && (
        <S.VideoWrapper>
          <iframe
            src={videoSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </S.VideoWrapper>
      )}
      <S.MoreBtnWrapper>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <S.MoreBtn>{t('more_btn')}</S.MoreBtn>
        </a>
        <S.ArrowIcon src={arrowIcon} alt="arrow-left" />
      </S.MoreBtnWrapper>
    </S.Media>
  );
};

export default SingleMediaItem;

SingleMediaItem.propTypes = {
  id: PropTypes.string.isRequired,
  mediaName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string,
  url: PropTypes.string.isRequired,
};

SingleMediaItem.defaultProps = {
  videoSrc: '',
};
