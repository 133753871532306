import React from 'react';
import PropTypes from 'prop-types';

import * as S from './SingleArticle.styles';
import { PrimaryButton } from '../Common/Buttons/Buttons.styles';
import articlesIcon from '../../assets/icons/articles-icon-orange.svg';

const SingleArticle = ({
  id,
  authors,
  title,
  content,
  footer,
  doi,
  publicURL,
}) => {
  return (
    <S.Article key={id}>
      <S.ArticleIcon src={articlesIcon} alt="article-icon" />
      <S.Authors>{authors.join(', ')}</S.Authors>
      <S.Title>{title}</S.Title>
      <S.Content>{content.split(' ').slice(0, 100).join(' ')}</S.Content>
      <S.Footer>{footer}</S.Footer>
      {doi && (
        <S.Doi>
          <S.DoiSpan>doi: </S.DoiSpan>
          <a href={doi} target="_blank" rel="noopener noreferrer">
            {doi}
          </a>
        </S.Doi>
      )}
      <a href={publicURL} target="_blank" rel="noreferrer">
        <PrimaryButton>Pobierz</PrimaryButton>
      </a>
    </S.Article>
  );
};

export default SingleArticle;

SingleArticle.propTypes = {
  id: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  doi: PropTypes.string,
  publicURL: PropTypes.string.isRequired,
};
