export const ARTICLES = [
  {
    id: '1',
    authors: ['Kmita M.', 'Lindner-Pawłowicz K.', 'Libura A.'],
    title:
      'Who does not find Metaphors Funny? Humor Preferences in Geriatric Patients',
    content:
      'Humor plays an important role throughout life, including old age. However, appreciating and understanding humor may be hindered due to dementia and late-life depression, two common old age-related diseases. The study reveals that the elderly participants found both humorous and non-humorous examples funnier than the control group. Elderly participants rated two types higher than the control group: the visual error-based jokes and non-visual metaphor-based jokes. The patients with a single disorder (cognitive disorder or depression) rated the funniness of the examples highest. Out of all participants with a single disorder, those with cognitive disorder rated the examples slightly higher than those with depression. Elderly participants are able to enjoy simple and familiar humor. While the perceived funniness of those with a single disorder may be a result of them using humor as a coping mechanism, such a mechanism no longer works in the case of coexisting dementia and depression.',
    footer:
      'Kmita M., Lindner -Pawłowicz K., Libura A.Communication Sciences and Disorders. 2022;27(2): 330-348.',
    doi: 'https://doi.org/10.12963/csd.21885',
    pdfName: 'metaphors',
  },
  {
    id: '2',
    authors: ['Kmita M.'],
    title:
      'Expectations and provocations: Use of humour between researcher and participants in humour research',
    content:
      'The purpose of this paper is to address participants’ humorous provocations as a part of informal interactions between participants and researcher that can be treated just like the research data. By means of autoethnographic analysis, the author explores the expectations of the researcher and participants that humour research entails and discusses how different expectations are revealed in participants’ provocations. This paper uses an autoethnographic approach to discuss the informal interactions between participants and the researcher gathered during research into staffroom humour. The informal interactions in general and humour specifically were recorded, analysed, coded, interpreted and theorised just like the data on humour between participants. The theoretical framework used in the study combines Goffman’s (1959) version of symbolic interactionism and Solomon et al. (2006) idea of hybrid spaces. The study shows the need for reconsideration of expectations entangled in humour research and proposes to be prepared for unexpected. Expecting unexpected can help stay open minded in the field and in interactions with participants and apply healthy distance towards own research and own expectations. The study shows that whenever certain behaviour was expected and different behaviour was delivered, there was a chance for certain behaviour being interpreted as provocations. Participants’ provocations can result from their own expectations about the research or what they think is expected from them by the researcher and thus they remain subject to different interpretations.',
    footer: 'Kmita M. Qualitative Research Journal, 2017; 17 (4): 265-282.',
    doi: 'https://doi.org/10.1108/QRJ-08-2016-0054',
    pdfName: 'expectations-and-provocations',
  },
  {
    id: '3',
    authors: ['Kmita M.', 'Mawhinney L.'],
    title:
      'A requirement and challenge of joke-ability in humor researcher: A fusion autoethnographic analysis',
    content:
      'With particular reference to qualitative humor research, this paper aims to look at fieldwork from a new angle. The purpose of this paper is to address humor research foci by completing a fusion autoethnographic analysis of how lead author used humor to interact with the participants. This analysis outlines the two examples of joke-ability; specifically self-deprecating humor and more generally attempts to blend in. This paper draws on fusion autoethnography where Author 2 actively worked to help Author 1 push deeper into her use of humor and its historical context within her life. This created a dialogue to deepen the self-analysis on Author 1’s humor methodology. The use of humor, by humor researchers, may be of particular importance if the researched groups, society, or nation values humor in both formal and informal contexts. Researcher’s humor can be a spontaneous and dynamic way of learning and engaging with the researched environment.',
    footer:
      'Kmita M., Mawhinney L. Qualitative Research Journal , 2016; 16 (1):92-107',
    doi: 'https://doi.org/10.1108/QRJ-10-2014-0051',
    pdfName: 'joke-ability',
  },
  {
    id: '4',
    authors: [
      'Kmita M.',
      'Bronowicka A.',
      'Moszczyńska A.',
      'Zych R.',
      'Małyszczak K.',
    ],
    title: 'Humour and Laughter therapy ',
    content:
      'The paper discusses the benefits of including a laughter therapy into group psychotherapy. The laughter therapy session was experimentally included in group psychotherapy. Laughter therapy consisted of various carefully designed humour-related tasks. The patients and the laughter therapist were observed via two-way mirror by experienced psychotherapists and a psychiatrist. The assessment of the experiment was based on the opinions of all three parties: patients, observers and the laughter therapists were documented. Patients enjoyed laughter therapy and, except one patient, took an active part in it. Patients were enthusiastic about this type of therapy and expressed willingness to participate in laughter therapy again. The therapy provided a two-tier liberation for patients: a liberation from their problems and from a typical therapy. It provided a return to childhood, evoking feelings of levity and spontaneity. It also strengthened in-group integration. The laughter therapy helped to unlock conscious experiencing of previously suppressed positive feelings in paranoid patients who experienced some ambivalence after laughter therapy. Laughter therapy can constitute a valuable part of group psychotherapy.',
    footer:
      'Kmita M., Bronowicka A., Moszczyńska A., Zych R., Małyszczak K. Psychoterapia 2017; 2 (181): 65–74',
    pdfName: 'humour-and-laughter-therapy',
  },
];
