import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import closeIcon from '../../assets/icons/close-icon.svg';

const PODCAST_URL =
  'https://open.spotify.com/embed/episode/2nXsrAEavjZaKlUSj0RmSO?utm_source=generator';

const StyledCloseIcon = styled.img`
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  cursor: pointer;
`;

const PodcastModal = ({ isOpen, closeModal }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <StyledCloseIcon src={closeIcon} alt="close icon" onClick={closeModal} />
      <iframe
        title="podcast"
        style={{ borderRadius: '12px' }}
        src={PODCAST_URL}
        width="100%"
        height="352"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    </ReactModal>
  );
};

export default PodcastModal;

PodcastModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
