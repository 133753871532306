/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import * as React from 'react';
import { MainLayout } from '../../components/MainLayout';
import BannerPublications from '../../components/Publications/BannerPublications';
import Book from '../../components/Publications/Book';
import OtherPublicationsDesktop from '../../components/Publications/DesktopComponents/OtherPublicationsDesktop';
import OtherPublicationsMobile from '../../components/Publications/MobileComponents/OtherPublicationsMobile';
import Podcast from '../../components/Publications/Podcast';
import { SEO } from '../../components/SEO';
import useMediaQuery from '../../hooks/useMediaQuery';
import { query as mediaQuery } from '../../styles/mediaQuery';
import routes from '../../static/constants/routes';
import { seoData } from '../../static/seoData';

const Publikacje = () => {
  const isDesktopLg = useMediaQuery(mediaQuery.desktopLg);

  return (
    <MainLayout>
      <BannerPublications />
      <Book />
      <Podcast />
      {isDesktopLg ? <OtherPublicationsDesktop /> : <OtherPublicationsMobile />}
    </MainLayout>
  );
};

export default Publikacje;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.publications[lang];

  return (
    <SEO
      title={metaData.title}
      description={metaData.description}
      pathname={routes.publications}
    />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
